:root {
  --amplify-primary-color: white;
  --amplify-primary-tint: black;
  --amplify-primary-shade: white;
  --amplify-primary-contrast: black;
}

.App {
  text-align: center;
  background-color: white;  
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  align-items: center;
  color: white;
}

.App-footer{
  background-color: #282c34;
  min-height: 3vh;
  text-align: right;
  color: white;
  font-size: small;
}



.Corps{
  color: white;

}



.App-link {
  color: #61dafb;
}



.Name{
  background-color:white;
  color: black;
  font-size: x-large;
  margin-left: 10px;
  width: 120%;
}



.NameM{
  width: 77%;
  font-size: 100%;
  align-items: center;
  text-align: left;
  margin-left: 5%;
}

.ourAudioPlayer{
  width: 100%;
  height: 100%;
 


}

.Photoperso{
  background-size: cover;
  background-position: center;
  display: flex;
  height: 300px;
}

.PlayButton{
  display: flex;
  background-color: #050505;  
}
.Player{
  background-size: cover;
  display: flex;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 2%;
  height: 150px;
}




/* Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
}

/* MS Browsers (Edge, Internet Explorer) */
* {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch; /* Touch scroll by default */
}





